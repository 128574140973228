module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://tegova.org","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TEGOVA","short_name":"TEGOVA","start_url":"/","background_color":"#ffffff","theme_color":"#004BA0","display":"standalone","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"929dd8d971ef85b20468f87ba188f855"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-FPQMYFRXKF","cookieName":"tegova-cookie-consent","anonymize":true,"allowAdFeatures":false},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","crumbSeparator":" > ","exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"crumbLabelUpdates":[{"pathname":"/news","crumbLabel":"News"},{"pathname":"/members","crumbLabel":"Members"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false,"headers":{"/*":["X-Frame-Options: allow-from https://intranet.tegova.org"]},"allPageHeaders":["X-Frame-Options: allow-from https://intranet.tegova.org"]},
    }]
