// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-page-homepage-js": () => import("./../../../src/components/page/homepage.js" /* webpackChunkName: "component---src-components-page-homepage-js" */),
  "component---src-components-page-member-association-js": () => import("./../../../src/components/page/member-association.js" /* webpackChunkName: "component---src-components-page-member-association-js" */),
  "component---src-components-page-news-index-js": () => import("./../../../src/components/page/news-index.js" /* webpackChunkName: "component---src-components-page-news-index-js" */),
  "component---src-components-page-news-js": () => import("./../../../src/components/page/news.js" /* webpackChunkName: "component---src-components-page-news-js" */),
  "component---src-components-page-page-js": () => import("./../../../src/components/page/page.js" /* webpackChunkName: "component---src-components-page-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-valuers-js": () => import("./../../../src/pages/valuers.js" /* webpackChunkName: "component---src-pages-valuers-js" */)
}

